import React from "react";
import { Link } from "react-router-dom";

function MenuCard({ menu }) {
    return (
        <Link to={ "/menus/" + menu.menuId } className="w-80 p-5 text-white bg-zinc-800 border-2 border-black border-opacity-25 drop-shadow-lg rounded-xl duration-300 hover:scale-105 hover:drop-shadow-xl">
            <div className="p-4 w-72">
                { menu.logo ? 
                    <img className="w-10 h-10 rounded-full float-right" src={ menu.logo } alt={ menu.name + "Logo" } /> :
                    <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full bg-gray-600 float-right">
                        <span className="font-medium uppercase text-white">{ menu.name.split(" ").map(x => x[0]).slice(0, 4) }</span>
                    </div>
                }
                
                <p className="text-xl font-bold truncate block capitalize">{ menu.name }</p>
                <p className="text-md font-bold truncate block capitalize">{ menu.category }</p>
                <div className="flex items-center">
                    <p data-te-toggle="tooltip" title={ menu.address } className="text-s font-semibold my-3 pr-3 hover:text-slate-200">{ menu.address }</p>
                </div>
            </div>
        </Link>
    )
};

export default MenuCard;