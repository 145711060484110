const SampleData = [
    {
        menuId: 1,
        name: "Ruchee",
        category: "Indian",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        logo: "https://ruchee.co.uk/mypal/2019/07/cropped-Ruchee512OG-192x192.png",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 2,
        name: "La Bella",
        category: "Pizza",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 3,
        name: "Mings",
        category: "Chinese",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        logo: "https://ruchee.co.uk/mypal/2019/07/cropped-Ruchee512OG-192x192.png",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 4,
        name: "Hins",
        category: "Chinese",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        logo: "https://ruchee.co.uk/mypal/2019/07/cropped-Ruchee512OG-192x192.png",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 5,
        name: "Barlby Fisheries",
        category: "Fish & Chips",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 6,
        name: "Golden Skewer",
        category: "Pizza",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        logo: "https://ruchee.co.uk/mypal/2019/07/cropped-Ruchee512OG-192x192.png",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 7,
        name: "Regency",
        category: "Chinese",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 8,
        name: "Ikkyu",
        category: "Sushi",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        logo: "https://ruchee.co.uk/mypal/2019/07/cropped-Ruchee512OG-192x192.png",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 9,
        name: "Thai Sunshine",
        category: "Thai",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        logo: "https://ruchee.co.uk/mypal/2019/07/cropped-Ruchee512OG-192x192.png",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 10,
        name: "Kezzy Burgers",
        category: "Burger",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 11,
        name: "The Olive Branch Bistro",
        category: "Turkish",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        logo: "https://ruchee.co.uk/mypal/2019/07/cropped-Ruchee512OG-192x192.png",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 12,
        name: "Caramba Steakhouse",
        category: "Brazillian",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        logo: "https://ruchee.co.uk/mypal/2019/07/cropped-Ruchee512OG-192x192.png",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 13,
        name: "Frying Nemo",
        category: "Fish & Chips",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 14,
        name: "Dough Outta Selby",
        category: "Pizza",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        logo: "https://ruchee.co.uk/mypal/2019/07/cropped-Ruchee512OG-192x192.png",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 15,
        name: "Selby Flame",
        category: "Grill",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    },
    {
        menuId: 16,
        name: "Ed's Easy Diner",
        category: "American",
        address: "9 Croft Road, Camblesforth Selby, YO8 9JL",
        phone: "01757 618144",
        logo: "https://ruchee.co.uk/mypal/2019/07/cropped-Ruchee512OG-192x192.png",
        categories: [
            {
                categoryId: 1,
                name: "Sides",
                items: [
                    {
                        itemId: 1,
                        name: "Boiled Rice",
                        description: "Just a portion of boiled rice.",
                        price: 3.00
                    },
                    {
                        itemId: 2,
                        name: "Keema Rice",
                        description: "Just a portion of rice mixed with minced mutton.",
                        price: 4.00
                    },
                ]
            },
            {
                categoryId: 2,
                name: "Mains",
                items: [
                    {
                        itemId: 1,
                        name: "Chicken Korma",
                        description: "Just a portion of chicken korma.",
                        price: 5.99
                    },
                    {
                        itemId: 2,
                        name: "Vindaloo",
                        description: "Just a portion of rice mixed with spicy vindaloo.",
                        price: 4.00
                    },
                ]
            }
        ]
    }
];

export default SampleData;