import { React, useEffect } from "react";
import MenuCard from "../components/MenuCard";
import Heading from "../components/Heading";
import SampleData from "../components/SampleData";

function Menus() {
    document.title = "MenuFindr | Menus";

    return (
        <div className="w-fit mx-auto mb-5">
            <Heading heading="All Menus" description="Take a look at our menus!" />
            <div className="py-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center justify-center gap-y-14 gap-x-14">
                { SampleData.map(x => <MenuCard menu={ x } />) }
            </div>
        </div>
    )
};

export default Menus;
