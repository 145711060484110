import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ItemBar from "../components/ItemBar";
import FilterButton from "../components/FilterButton";
import Heading from "../components/Heading";
import SampleData from "../components/SampleData";
import ItemCard from "../components/ItemCard";
import Button from "../components/Button";

function ViewMenu() {
    const { menuId } = useParams();
    
    // Code to get menu from db and setMenu(dbMenu);
    const menu = SampleData.find(x => x.menuId === Number(menuId)); // Return to NoPage if invalid menu
    
    const [ itemsToShow, setItems ] = useState(menu.categories.at(0).items);

    useEffect(() => {
        document.title = "MenuFindr | " + menu.name;
    });

    return (
        <div className="px-5 w-screen mx-auto mb-5 md:px-10 lg:w-3/4">
            <Heading heading={ menu.name } description={ menu.address } 
                button={ <Button url={ "tel:" + menu.phone } text={ menu.phone } icon={
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="pl-2 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                    </svg>
                } /> 
            } />
            <ItemBar items={ menu.categories.map( x => <FilterButton key={ x.categoryId } colour="#fff000" itemName={ x.name } clickFunction={ () => setItems(x.items) } />) }/>
            <div className="py-10 grid grid-cols-1 justify-items-center justify-center gap-y-3">
                { itemsToShow.map(x => <ItemCard key={ x.itemId } item={ x } />) }
            </div>
        </div>
    )
};

export default ViewMenu;