import { useState } from "react";
import { Link } from "react-router-dom";

function Navbar() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const isDevelopmentServer = window.location.hostname.toLowerCase() !== "menufindr.co.uk";

    return (
        <nav className="flex fixed items-center justify-between flex-wrap bg-zinc-900 p-6 z-10 w-full drop-shadow-xl border-b-2 border-black border-opacity-25">
            <Link to="/" className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-7 lg:mb-1">
                <img src="/logo.png" className="h-16 mr-3" alt="MenuFindr Logo" />
                <span className="mr-4 font-semibold text-xl lg:text-2xl tracking-tight">MenuFindr</span>
                { isDevelopmentServer ? 
                <span className="mt-2 font-semibold text-sm tracking-tight text-yellow-400 lg:hidden">Development</span> : null }
            </Link>
            <div className="block lg:hidden">
                <button className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-white hover:border-white" onClick={() => setIsNavOpen((prev) => !prev)}>
                    <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                </button>
            </div>
            <div className={(isNavOpen ? "block" : "hidden") + " w-full flex-grow lg:flex lg:items-center lg:w-auto"}>
                <div className="w-full text-sm lg:flex-grow">
                    <Link to="/" className="block mt-4 lg:text-lg lg:font-semibold lg:inline-block lg:ml-4 lg:mt-0 text-white hover:text-slate-200 mr-4">
                        Home
                    </Link>
                    <Link to="/menus" className="block mt-4 lg:text-lg lg:font-semibold lg:inline-block lg:ml-4 lg:mt-0 text-white hover:text-slate-200 mr-4">
                        Menus
                    </Link>
                    <Link to="/contact" className="block mt-4 lg:text-lg lg:font-semibold lg:inline-block lg:ml-4 lg:mt-0 text-white hover:text-slate-200">
                        Contact Us
                    </Link>
                </div>
                { isDevelopmentServer ? 
                <div className="text-sm sm:hidden md:hidden lg:block">
                    <p className="block mt-4 lg:text-lg lg:font-semibold lg:inline-block lg:ml-4 lg:mt-0 text-yellow-400">Development</p>
                </div> : null }
            </div>
        </nav>
    )
};

export default Navbar;