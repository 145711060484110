import { useEffect } from "react";

function Contact() {
    useEffect(() => {
        document.title = "MenuFindr | Contact Us";
    });

    return (
        <h1 className="w-fit mx-auto mb-5">
            Contact us
        </h1>
    )
}

export default Contact;