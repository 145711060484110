import React from "react";
import { NumericFormat } from "react-number-format";

function ItemCard({ item }) {
    return (
        <div className="w-full px-6 py-4 text-white bg-zinc-800 border-2 border-black border-opacity-25 drop-shadow-lg rounded-xl duration-300 hover:scale-105 hover:drop-shadow-xl">
            <p className="text-xl font-bold truncate block capitalize">{ item.name }</p>
            <p className="text-md truncate block">{ item.description }</p>
            <div className="flex items-center">
                <NumericFormat className="text-s font-semibold my-3 pr-3 hover:text-slate-200" value={ item.price } displayType={ 'text' } prefix={ '£' } decimalScale={ 2 } fixedDecimalScale />
            </div>
        </div>
    )
};

export default ItemCard;