import { useEffect } from "react";

function NoPage() {
    useEffect(() => {
        document.title = "MenuFindr | 404";
    });

    return (
        <div className="w-fit mx-auto mb-5">
            <h1 className="text-center text-white text-9xl">404</h1>
            <h1 className="text-center text-white text-9xl">Not Found</h1>
        </div>
    )
};

export default NoPage;