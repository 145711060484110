import { Link } from "react-router-dom";

function Footer() {
    return (
        <>
            <footer className="w-screen bottom-0 p-4 bg-zinc-900 border-t-2 border-black border-opacity-25 shadow md:px-6 md:py-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <a href="https://menufindr.co.uk/" className="flex items-center mb-4 sm:mb-0">
                        <img src="/logo.png" className="h-20 mr-3" alt="MenuFindr Logo" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">MenuFindr</span>
                    </a>
                    <ul className="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0">
                        <li>
                            <Link to="/" className="mr-4 hover:underline md:mr-6 ">Home</Link>
                        </li>
                        <li>
                            <Link to="/menus" className="mr-4 hover:underline md:mr-6">Menus</Link>
                        </li>
                        <li>
                            <Link to="/contact" className="mr-4 hover:underline">Contact Us</Link>
                        </li>
                    </ul>
                </div>
                    <hr className="my-6 sm:mx-auto border-zinc-800 border-opacity-100 lg:my-8" />
                    <span className="block text-sm text-gray-500 sm:text-center">© <a href="https://menufindr.co.uk/" className="hover:underline">MenuFindr™</a>. All Rights Reserved.
                </span>
            </footer>
        </>
    )
};

export default Footer;