function FilterButton({ colour, itemName, clickFunction }) {
    return (
        <button onClick={ clickFunction } className="p-2 flex rounded-full bg-zinc-800 border-2 border-black border-opacity-25 drop-shadow-lg duration-200 hover:scale-105 hover:drop-shadow-xl">
            <div className="h-1 w-1 p-2 my-auto rounded-full border-2 border-black border-opacity-25" style={{ background: colour }}></div>
            <p className="ml-2 my-auto float-right text-white">
                { itemName }
            </p>
        </button>
    )
};

export default FilterButton;