import { Link } from "react-router-dom";

function Button({ url, text, icon }) {
    return (
        <Link to={ url || null } className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white drop-shadow-lg bg-zinc-800 border-2 border-black border-opacity-25 rounded-lg duration-300 hover:scale-105 hover:drop-shadow-xl">
            { text || null }
            { icon || null }
        </Link>
    )
};

export default Button;