import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Layout() {
    return (
        <div className="bg-zinc-900 flex flex-col min-h-screen justify-between">
            <Navbar />
            <div className="pt-48">
                <Outlet />
            </div>
            <Footer />
        </div>
    )
};

export default Layout;