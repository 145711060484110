function Heading({ heading, description, button, className }) {
    return (
        <div className={ className }>
            <div className="pb-10 border-b-2 border-zinc-900">
                <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-wide text-white md:text-5xl lg:text-6xl">{ heading }</h1>
                <p className="mb-6 text-lg font-normal text-white lg:text-xl">{ description || null }</p>
                { button || null }
            </div>
        </div>
    )
};

export default Heading;