import { React, useEffect } from "react";
import MenuCard from "../components/MenuCard";
import Heading from "../components/Heading";
import SampleData from "../components/SampleData";
import Button from "../components/Button";

function Home() {
    useEffect(() => {
        document.title = "MenuFindr | Home";
    });

    return (
        <div className="w-fit mx-auto mb-5">
            <Heading heading="Hungry?" description="Take a look at our menus!" 
                button={ <Button url="/menus" text="Take a look" icon={
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="pl-2 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                    </svg>
                } /> 
            } />
            <div className="mx-auto px-auto py-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center justify-center gap-y-14 gap-x-14">
                { SampleData.slice(0, 8).map(x => <MenuCard key={ x.menuId } menu={ x } />) }
            </div>
        </div>
    )
};

export default Home;